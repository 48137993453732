import React from "react"

import Layout from "../layouts"
import SEO from "../layouts/seo"

export default (props) => (
  <Layout location={props.location}>
    <div className="tool">
      <SEO title="404: Not found" />
      <h1>Não encontrei a página...</h1>
      <p>Selecione uma ferramenta no menu ao lado.</p>
    </div>
  </Layout>
)
